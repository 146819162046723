// Objeto con propiedades de configuración global del proyecto.
const Config = {
	axios: {
		hostname: '',
		port: -1,
		protocol: ''
	},
	entries: {
		removeRootRole: true,
		removeRootUser: true,
		removeRootWorkArea: true
	}
}

// HostNames Validos.
enum ValidHostNames {
	DEVELOPMENT   = 'test.kreisnet.cl',
	LOCALHOST     = 'localhost',
	PRODUCTION    = 'kreisnet.cl',
	MIRROR_TEST_1 = 'kreisnet.aaga.cl'
}

// Puertos Validos.
enum ValidPorts {
	DEVELOPMENT   = 3000,
	LOCALHOST     = 3001,
	PRODUCTION    = 3000,
	MIRROR_TEST_1 = 3000
}

// Helper Function: Resuelve el Host del Servidor.
function _resolveHostName(v: any) {
	if (typeof v === 'number') {
		switch (v) {
			case 0: return ValidHostNames.DEVELOPMENT
			case 1: return ValidHostNames.LOCALHOST
			case 2: return ValidHostNames.PRODUCTION
			case 3: return ValidHostNames.MIRROR_TEST_1
		}
	}
	return ValidHostNames.DEVELOPMENT
}

// Helper Function: Resuelve el Puerto del Servidor.
function _resolvePort(v: any) {
	if (typeof v === 'number') {
		switch (v) {
			case 0: return ValidPorts.DEVELOPMENT
			case 1: return ValidPorts.LOCALHOST
			case 2: return ValidPorts.PRODUCTION
			case 3: return ValidPorts.MIRROR_TEST_1
		}
	}
	return ValidPorts.DEVELOPMENT
}

// Helper Function: Resuelve el Protocolo del Servidor.
function _resolveProtocol(v: any) {
	if (typeof v === 'number') {
		switch (v) {
			case 1:  return 'http'
			default: return 'https'
		}
	}
	return 'https'
}

// Especificación del Hostname segun el Entorno y la Especificación.
Config.axios.hostname = _resolveHostName(VUE_APP_HOSTNAME)
Config.axios.port     = _resolvePort(VUE_APP_HOSTNAME)
Config.axios.protocol = _resolveProtocol(VUE_APP_HOSTNAME)

// Config Export
export default Config