// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'

// Components
import DatePicker        from '@/Components/Extras/DatePicker/template.vue'
import PeriodPicker      from '@/Components/Extras/PeriodPicker/template.vue'
import PopupTable        from '@/Components/Global/PopupTable/template.vue'
import { PopupTableRef } from '@/Components/Global/PopupTable/component'

// Constants
import { Component } from '@/Constants/Global/Component'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinBase       from '@/Mixins/MixinBase'
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const DataForm = VueMixins(MixinBase, MixinComponent, MixinResponsive).extend({
	name: 'DataForm',

	components: {
		DatePicker,
		PeriodPicker,
		PopupTable
	},

	data: function() {
		return {
			params: undefined,
			tabs: [],
			states: {
				action: Component.Actions.INSERT,
				showPopupTable: false,
				validateState: true
			}
		}
	},

	computed: {
		_popupTable: function(): PopupTableRef {
			return this.$refs.popupTable as PopupTableRef
		}
	},

	methods: {
		_checkRequired: function(item: Item) {
			// El Elemento puede ser deshabilitado/habilitado si la propiedad 'enabled' esta presente
			// debido al llamado de la función setEnabled.
			if (item.params?.enabled) {
				return !item.params.enabled
			}

			// El Elemento puede ser deshabilitado si la propiedad 'readOnlyAt' esta presente.
			if (item.params?.readOnlyAt) {
				return this._checkReadOnly(item)
			}

			// El Elemento puede ser deshabilitado si la propiedad 'requires' esta presente.
			else if (item.params?.requires) {
				const requiredField = item.params.requires.field
				for (let n = 0; n < this.tabs.length; n++) {
					for (const $i of this.tabs[n].columns) {
						if ($i.key === requiredField) {
							// Si la propiedad 'values' esta presente, evaluar su valor,
							// de lo contrario, validar por defecto valores vacios/nulos.
							const values = item.params.requires?.values
							if (values) {
								return this._validateValues($i, values)
							}
							else {
								switch ($i.type) {
									case 'input': return $i.value === ''
									case 'input-button': return $i.data.value === ''
									case 'select': return $i.selected === null
								}
							}
						}
					}
				}
			}
			return false
		},

		_checkReadOnly: function(item: Item) {
			// El Elemento puede ser deshabilitado si la propiedad 'readOnlyAt' esta presente.
			if (item.params?.readOnlyAt) {
				return Array.isArray(item.params.readOnlyAt)
					? item.params.readOnlyAt.includes(this.states.action)
					: item.params.readOnlyAt === this.states.action
			}
			return false
		},

		_defaultInputStateValidator: function(self: any) {
			if (self?.data !== undefined && self.data.value === '') return null
			if (self?.selected !== undefined && self.selected === null) return null
			if (self?.value !== undefined && self.value === '') return null
			return true
		},

		_getColumn: function(index: number) {
			const currentTab = this._getCurrentObjectTab()
			if (currentTab) {
				if (currentTab?.columns) {
					// Los elementos deben ser filtrados antes de decidir a cual columna corresponden.
					const auxItems = currentTab.columns.filter((item: any) => {
						if (item?.params && (item.params?.visible !== undefined)) {
							if (item.params.visible === this.states.action) return true
						}
						else { return true }
					})

					// El ultimo filtro corresponde al 'index' de la columna.
					const items: Array<any> = []
					for (let step = 0; step < auxItems.length; step++) {
						if (step % this.params.numColumns === index) {
							const item = auxItems[step]
							items.push(item)
						}
					}

					// Retornar el Array con los items.
					return items
				}
			}
			return []
		},

		_getCurrentObjectTab: function() {
			if (this.tabs.length > 0) {
				for (let $t of this.tabs) {
					if (this.getCurrentTab() === $t.header.key) {
						return $t
					}
				}
			}
			return undefined
		},

		_getDisabledClass: function(state: boolean) {
			return state ? 'disabled' : ''
		},

		_getHeaderButtonClass: function(button: Button) {
			let cls = ''
			if (this.getCurrentTab() === button.key) cls += 'button-active ';
			if (button?.isFirst === true) cls += 'button-is-first ';
			if (button?.isLast === true) cls += 'button-is-last ';
			return cls.trim()
		},

		_getHeaderButtonStyle: function() {
			const { length } = this._getHeaderButtons()
			return `width: calc(100% / ${length});`
		},

		_getHeaderButtonText: function(button: Button) {
			switch (this.states.action) {
				case Component.Actions.INSERT: return button.text.onInsert
				case Component.Actions.READ  : return button.text.onSelect
				case Component.Actions.UPDATE: return button.text.onUpdate
			}
			return ''
		},

		_getHeaderButtons: function() {
			if (this.tabs.length > 0) {
				const buttons: Array<Button> = []
				for (let $t of this.tabs) {
					buttons.push($t.header)
				}
				return buttons
			}
			return []
		},

		_getInputButtonClass: function(item: Item) {
			let classString = !this._checkRequired(item) ? 'btn-green' : 'btn-gray'
			if (item.params?.button) {
				if (item.params.button.content === 'icon') {
					classString += ' has-icon'
				}
				else if (item.params.button.content === 'text') {
					classString += ' has-text'
				}
			}
			return classString
		},

		_getInputFormatter: function(item: Item) {
			return item.params?.formatter ? item.params.formatter : undefined
		},

		_getInputPasswordVisibilityIcon: function(item: any) {
			return item.visibility ? 'eye' : 'eye-slash'
		},

		_getInputState: function(item: Item, stateFunctionName = 'state') {
			if (item?.params && item.params?.[stateFunctionName]) {
				return item.params[stateFunctionName](item)
			}
			return null
		},

		_getInputType: function(item: Item) {
			switch (item.params.type) {
				case 'phone':
					return 'text'
				// case 'textarea':
				// 	return 'text'
				default:
					if (item.params.type === 'password' && item.visibility) return 'text'
					return item.params.type
			}
		},

		_getRows: function() {
			const $t = this._getCurrentObjectTab()
			if ($t !== undefined) {
				if ($t?.rows) {
					// Segun el indice de la columna, insertar los items correspondientes.
					const items = []
					for (let i = 0; i < $t.rows.length; i++) {
						const item = $t.rows[i]
						if (item !== undefined) {
							if (item?.params && (item.params?.visible !== undefined)) {
								if (item.params.visible === this.states.action) {
									items.push(item)
								}
							}
							else items.push(item)
						}
					}
					return items
				}
			}
			return []
		},

		_getPlaceHolder: function(item: Item, state = false) {
			if (state) {
				if (item.params?.requires && item.params.requires?.friendly) {
					return `Debe seleccionar el campo '${item.params.requires.friendly}' primero...`
				}
			}
			return item.params.placeholder || ''
		},

		_getSelectFirstOption: function(item: Item) {
			if (item.params?.placeholder) {
				return item.params.placeholder
			}
			return '- Seleccione una Opción -'
		},

		_isOptional: function(item: Item) {
			if (item.params?.optional) {
				return item.params.optional
			}
			return false
		},

		_resolveEventHandlers: function(item: Item, nativeEvent: any) {
			if (item?.events) {
				return item.events?.[nativeEvent.type] ? item.events.keyup(item, nativeEvent) : null
			}
			return null
		},

		_validateValues: function(item: Item, values: Array<any>) {
			// 'values' puede ser de tipo String o Array.
			if (Array.isArray(values)) {
				switch (item.type) {
					case 'input': return !(values.includes(item.value))
					case 'input-button': return !(values.includes(item.data.value))
					case 'select': return !(values.includes(item.selected))
				}
			}
			else if (typeof values === 'string') {
				switch (item.type) {
					case 'input': return !(item.value === values)
					case 'input-button': return !(item.data.value === values)
					case 'select': return !(item.selected === values)
				}
			}
			return undefined
		},

		/* <=================|==============================|=================> */
		/* <=================| PUBLIC DECLARATION FUNCTIONS |=================> */
		/* <=================|==============================|=================> */

		addOption: function(key: string, option: object) {
			const $e = this.getElement(key)
			if ($e !== undefined) {
				switch ($e.type) {
					case 'select': 
						$e.options.push(option)
						break
				}
			}
		},

		clearInputs: function() {
			for (const $t of this.tabs) {
				for (const $k of ['columns', 'rows']) {
					if ($t?.[$k] !== undefined) {
						for (const $i of $t[$k]) {
							switch ($i.type) {
								case 'datepickerelement':
								case 'datepicker':
								case 'input':
								case 'textarea':
									$i.value = ''
									break
								case 'input-button':
									delete $i.data._id
									$i.data.value = ''
									break
								case 'periodpicker':
									$i.value = []
									break
								case 'select':
									$i.selected = null
									break
							}
						}
					}
				}
			}
		},

		clearOptions: function(key: string) {
			const $e = this.getElement(key)
			if ($e !== undefined) {
				switch ($e.type) {
					case 'select': 
						$e.options = []
						break
				}
			}
		},

		getCurrentTab: function() {
			return this.params.currentTab
		},

		getElement: function(key: string) {
			const { currentTab } = this.params
			for (const $t of this.tabs) {
				if ($t.header.key === currentTab) {
					for (const $k of ['columns', 'rows']) {
						if ($t?.[$k] !== undefined) {
							for (const $i of $t[$k]) {
								if ($i.key.toLowerCase() === key.toLowerCase()) {
									return $i
								}
							}
						}
					}
					break
				}
			}
		},

		getNumColumns: function(tabName?: string) {
			if (this.tabs.length > 0) {
				for (let $t of this.tabs) {
					if ($t.key === tabName) {
						if ($t?.numColumns) {
							return $t.numColumns
						}
						else {
							return this.params.numColumns
						}
					}
				}
			}
			return 0
		},

		getValue: function(key: string, dataProperty = '') {
			const $e = this.getElement(key)
			if ($e !== undefined) {
				switch ($e.type) {
					case 'checkbox':
						return $e.checked
					case 'datepickerelement':
						return $e.value
					case 'datepicker':
						return $e.value
					case 'input':
					case 'textarea':
						if (typeof $e.value === 'number') return $e.value
						return $e.value.trim()
					case 'input-button':
						return $e.data[dataProperty]?.trim()
					case 'select':
						return $e.selected?.trim()
					case 'periodpicker':
						const { value } = $e
						return `${value[0]} - ${value[1]}`
				}
			}
		},

		initialize: function(dataForm: { params: any, tabs: any }) {
			this.params = dataForm.params
			this.tabs = dataForm.tabs
		},

		initPopupTable: function(fields: Array<any>, items: Array<any>) {
			const { _dataTable } = this._popupTable
			_dataTable.setElements(fields, items)
		},

		setEnabled: function(key: string, state: boolean) {
			const $e = this.getElement(key)
			if ($e !== undefined) {
				switch ($e.type) {
					case 'input-button':
						$e.params.enabled = state
						break
				}
			}
		},
	
		setValue: function(key: string, value: any, dataProperty = '') {
			const $e = this.getElement(key)
			if ($e !== undefined) {
				switch ($e.type) {
					case 'checkbox':
						$e.checked = value
						break
					case 'datepickerelement':
					case 'datepicker':
					case 'input':
					case 'periodpicker':
					case 'textarea':
						$e.value = value
						break
					case 'input-button':
						$e.data[dataProperty] = value
						break
					case 'select': 
						$e.selected = value
						break
				}
			}
		},

		validateStates: function() {
			for (let col = 0; col < this.getNumColumns(); col++) {
				for (const $item of this._getColumn(col)) {
					if ($item?.params && $item.params?.state) {
						if ($item.params.state($item) === false) {
							return false
						}
					}
				}
			}
			return true
		},

		/* <=================|=============================|==================> */
		/* <=================| EVENT DECLARATION FUNCTIONS |==================> */
		/* <=================|=============================|==================> */

		onDFCurrentSearchPaginationChanged: function(page: number, searchParams: any) {
			DevelopmentTools.printWarn('[DataForm]:onDFCurrentSearchPaginationChanged event triggered')
			this.$emit('onDFCurrentSearchPaginationChanged', page, searchParams)
		},

		onDFPaginationChanged: function(page: number) {
			DevelopmentTools.printWarn('[DataForm]:onDFPaginationChanged event triggered')
			this.$emit('onDFPaginationChanged', page)
		},

		onDFRefreshButtonClick: function() {
			DevelopmentTools.printWarn('[DataForm]:onDFRefreshButtonClick event triggered')
			this.$emit('onDFRefreshButtonClick')
		},

		onDFSearchButtonClicked: function(searchParams: any) {
			DevelopmentTools.printWarn('[DataForm]:onDFSearchButtonClicked event triggered')
			this.$emit('onDFSearchButtonClicked', searchParams)
		},

		onInputButtonClick: function(key: string, event: Event) {
			DevelopmentTools.printWarn('[DataForm]:onInputButtonClick event triggered')
			this.$emit('onInputButtonClick', key, event)
		},

		onOptionChange: function(key: string, event: Event) {
			DevelopmentTools.printWarn('[DataForm]:onOptionChange event triggered')
			this.$emit('onOptionChange', key, event)
		},

		onPTClose: function() {
			DevelopmentTools.printWarn('[DataForm]:onPTClose event triggered')
			this.setStates<DataFormRef['states']>({ showPopupTable: false })
		},

		onPTSelect: function(currentRow: object) {
			DevelopmentTools.printWarn('[DataForm]:onPTSelect event triggered')
			if (currentRow !== undefined) {
				this.setStates<DataFormRef['states']>({ showPopupTable: false })
				this.$emit('onPTSelect', currentRow)
			}
		},

		onRowDoubleClick: function(clickedRow: object) {
			DevelopmentTools.printWarn('[DataForm]:onRowDoubleClick():Event triggered')
			this.setStates<DataFormRef['states']>({ showPopupTable: false })
			this.$emit('onRowDoubleClick', clickedRow)
		},

		onSetCurrentTab: function(tabName: string) {
			if (this.getCurrentTab() !== tabName) {
				this.params.currentTab = tabName
			}
		}
	}
})

// Exports
export default DataForm
export type DataFormRef = InstanceType<typeof DataForm>

interface Button {
	key: string
	isFirst?: boolean
	isLast?: boolean
	text: {
		onInsert?: string
		onSelect?: string
		onUpdate?: string
	}
}

interface ButtonParam {
	content: string
}

interface Event {
	[key: string]: (Function | string)
	function?: Function
	keyup?: Function
	name?: string
}

interface Item {
	[key: string]: (Event | Param | string)
	data?: { value: any }
	events?: Event
	params?: Param
	type?: string
}

interface Param {
	[key: string]: any
	button?: ButtonParam
	enabled?: boolean
	formatter?: Function
	optional?: boolean
	placeholder?: string
	readOnlyAt?: number
	requires?: RequiresParam
	state?: Function
	type: string
}

interface RequiresParam {
	field?: string
	friendly?: string
	values?: any[]
}