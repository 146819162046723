export namespace Server {
	export namespace Fetching {
		export enum Method {
			GET   = 'GET',
			PATCH = 'PATCH',
			POST  = 'POST',
			PUT   = 'PUT'
		}
	
		export enum Resolutions {
			NONE,
			USER_AUTHENTICATED
		}
	
		export enum Validations {
			CHECKLIST_ADDONS,
			CHECKLIST_SETTINGS,
			COMPANIES,
			EQUIPMENTS,
			INSTALLATIONS,
			PERMISSIONS,
			REGIONS,
			ROLES,
			SERVICES,
			STORAGES,
			SYSTEMS,
			USERS,
			WORKAREAS
		}
	}

	export namespace Response {
		export enum StatusCodes {
			SUCCESS              = 200,
			UNAUTHORIZED         = 401,
			CONFLICT             = 409,
			EXPECTATION_FAILED   = 417,
			UNPROCESSABLE_ENTITY = 422,
			INTERNAL_ERROR       = 500,
			NULL_RESPONSE        = null
		}
	
		export enum UnprocessableEntities {
			EMPTY_STRING = 'string.empty',
			NOT_A_NUMBER = 'number.base'
		}
	}
	
	export namespace Routes {
		export enum CheckLists {
			AddCheckListAddons = '/checklists/add/addons',
			AddInstallation = '/checklists/installations/add',
			GetActiveCheckListsForStorageByPage = 'checklists/get/equipments/storage/pagination',
			GetActiveCheckListsForStorageByFilter = 'checklists/get/equipments/storage/filter',
			GetAllAddonsForFilter = '/checklists/get/all/addons/filter',
			GetAllInstallationFilter = '/checklists/installations/get/all/filter',
			GetCheckListsAddonsAssociationFilter= '/checklists/get/all/addons/associated/filter',
			GetCheckListsAddons = '/checklists/get/all/addons',
			GetCheckListsAddonsByDefaultAssociation = '/checklists/get/all/addons/associated',
			GetCheckListsAddonsByIdStorage = '/checklists/get/checklist/addons/storage',
			GetCheckListsByFilter = '/checklists/get/kpi-num',
			GetCheckListsByIdStorage = '/checklists/get/all/storage',
			GetCheckListsBySearchFilter = '/checklists/get/all/storage-filter',
			GetCheckListsSettings = '/checklists/setting/get/all',
			GetCheckListsStoragesByUser = '/checklists/get/storages',
			GetEquipmentCheckListsByStorage = '/checklists/get/equipments/storage',
			GetEquipmentCheckListsForStorageByFilter = '/checklists/get/all/equipments-storage/filter',
			GetFullAddons = '/checklists/get/full/addons',
			GetAllAddonsFormByIdStorageFilter='/checklists/get/checklist/addons/storage/filter',
			GetFullAddonsFilter = '/checklists/get/full/addons/filter',
			GetInstallations = '/checklists/installations/get/all',
			GetInstallationsAssociatedFilter = '/checklists/installations/get/all/associated/filter',
			GetInstallationById = '/checklists/installations/get/one',
			GetInstallationAssociated = '/checklists/installations/get/all/associated',
			PutCheckListsSettingAuditCode = '/checklists/setting/update/form-code',
			UpdateCheckListAddons = '/checklists/update/one/addons',
			UpdateInstallation = '/checklists/installations/update'
		}

		export enum Communes {
			GetCommunes = '/communes/get/all',
			GetCommunesByRegion = '/communes/get/one/region'
		}

		export enum Companies {
			AddCompany = '/companies/add',
			GetCompanies = '/companies/get/all',
			GetCompaniesByPage = '/companies/get/all/pagination',
			GetCompaniesBySearchFilter = '/companies/get/filter',
			UpdateCompany = '/companies/update'
		}

		export enum Commons {
			GetSystems = '/systems/get/all',
			Login = '/login/session'
		}

		export enum Emails {
			RequestPassword = '/emails/request/password',
			VerifyCode  = '/emails/verify/code',
		}

		export enum Equipments {
			AddEquipment = '/equipments/add',
			GetEquipments = '/equipments/get/all',
			GetEquipmentsByPage = '/equipments/get/all/pagination',
			GetEquipmentsBySearchFilter = '/equipments/get/filter',
			UpdateEquipment = '/equipments/update'
		}

		export enum Notifications {
			GetNotificationsById = '/notifications/get/id',
			GetNotificationsByIdEquipment = '/notifications/get/id-equipment',
			GetNotificationsByIdRegister = '/notifications/get/id-register',
			GetNotificationsByIdStorage = '/notifications/get/id-storage',
			GetNotificationsByIdSystem = '/notifications/get/id-system',
			UpdateNotificationById = '/notifications/update/id',
			UpdateNotificationReadById = '/notifications/update/read/id'
		}

		export enum Operators {
			AddOperator = '/operators/add',
			GetOperators = '/operators/get/all',
			GetOperatorById = '/operators/get/one',
			UpdateOperator = '/operators/update'
		}

		export enum Permissions {
			GetPermissions = '/permissions/get/all'
		}

		export enum Roles {
			AddRole = '/roles/add',
			GetRoles = '/roles/get/all',
			GetRolesByPage = '/roles/get/all/pagination',
			GetRoleById = '/roles/get/one',
			GetRolesBySearchFilter = '/roles/get/filter',
			UpdateRole = '/roles/update'
		}

		export enum Services {
			AddService = '/services/add',
			GetServices = '/services/get/all',
			UpdateService = '/services/update/one'
		}

		export enum ServicesRequest {
			GetServicesAll ='/services/get/all',
			GetServicesRequestBySearchFilter = '/services/get/filter'
		}

		export enum Storages {
			AddStorage = '/storages/add',
			GetStorages = '/storages/get/all',
			GetStoragesByIdCompany = '/storages/get/id-company',
			GetStoragesByPage = '/storages/get/all/pagination',
			GetStoragesBySearchFilter = '/storages/get/filter',
			UpdateStorage = '/storages/update',
		}

		export enum Users {
			AddUser = '/users/add',
			GetUsers = '/users/get/all',
			GetUsersByPage = '/users/get/all-pagination',
			GetUsersBySearchFilter = '/users/get/filter',
			UpdateUser = '/users/update',
			UpdateUserPassword = '/users/reset/password',
			UpdateUserPermissions = '/users/update/permissions'
		}

		export enum WorkAreas {
			AddWorkArea = '/workareas/add',
			GetWorkAreas = '/workareas/get/all',
			GetWorkAreasByPage = '/workareas/get/all/pagination',
			GetWorkAreasBySearchFilter = '/workareas/get/all/filter',
			UpdateWorkArea = '/workareas/update'
		}

		export enum Zones {
			AddZones = '/zones/add',
			GetEquipmentsByStorageFilter = '/zones/get/all/equipments-storage/filter',
			GetEquipmentsForStorage = '/zones/get/all/equipments-storage',
			GetStoragesByUser = '/zones/get/all/storages-user',
			GetZonesByStorage = '/zones/get/all/zones-storage',
			GetZonesByStorageFilter = '/zones/get/all/zones-storage/filter',
			GetZonesByUser = '/zones/get/all/zones-user',
			GetZonesByUserFilter = '/zones/get/all/zones-user/filter',
			UpdateZone = '/zones/update',
			UpdateZoneEquipment = '/zones/update/equipments-zone'
		}
	}
}