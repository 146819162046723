// Classes
import { DatePickerElement } from '@/Components/Global/DataForm/Classes/DatePickerElement'
import { InputButton }       from '@/Components/Global/DataForm/Classes/InputButton'
import { InputTextArea }     from '@/Components/Global/DataForm/Classes/InputTextArea'
import { Select }            from '@/Components/Global/DataForm/Classes/Select'

// Constants
import { Component } from '@/Constants/Global/Component'

// Form Export
export default {
	params: {
		currentTab: 'services',
		numColumns: 2,
	},
	tabs: [
		{
			header: {
				key: 'services',
				text: { onInsert: 'Solicitud de Atención: Aprobar', onUpdate: 'Solicitud de Atención: Rechazar', onSelect: 'Solicitud de Atención' }
			},
			columns: [
				new InputTextArea('Description', 'Descripción del Servicio')
				.setReadOnly([Component.Actions.UPDATE, Component.Actions.READ, Component.Actions.INSERT]),				
				new InputTextArea('details','Detalle del Servicio')
				.setReadOnly([Component.Actions.UPDATE, Component.Actions.READ, Component.Actions.INSERT]),
				new Select('technicalName', 'Reasignar Técnico').setReadOnly([Component.Actions.READ, Component.Actions.UPDATE]),
				new Select('statusEquipment', 'Estatus Equipo',['Operativo', 'Detenido']).setReadOnly([Component.Actions.READ, Component.Actions.UPDATE]),
				//new Select('status', 'Estado Solicitud', ['Abierto','Rechazado','En Curso','Finalizado']),
				new InputButton('equipment', 'Equipo').setIcon('pen').setReadOnly([Component.Actions.INSERT, Component.Actions.READ, Component.Actions.UPDATE]),
				new DatePickerElement('dateProgram', 'Fecha Reparación').setReadOnly([Component.Actions.READ, Component.Actions.UPDATE]),
				new Select('workingDay', 'Jornada',['Mañana', 'Tarde']).setReadOnly([Component.Actions.READ, Component.Actions.UPDATE]),
				new Select('Motivos', 'Motivo de rechazo',['No disponible el repuesto necesario','No es una falla técnica','Falta de recursos técnicos','No cubre la garantía']).setVisible(Component.Actions.UPDATE),

			]
		}
	]
}