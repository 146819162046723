// Classes
import { ActionCallbackName, ActionField } from '@/Classes/Records/ActionField'
import { ActionItem } from '@/Classes/Records/ActionItem'
import { ValueTools } from '@/Classes/Static/ValueTools'

// Dependencies
import Joi from 'joi'

// Namespace Export
export namespace Module3 {
	export namespace M30 {
		const TempColorStates: { [key: string]: string } = {
			Abierto:     'blue',
			Rechazado:   'red',
			'En Curso':  'orange',
			Finalizado:   'green',
		}

		export namespace ActionFields {
			const TempActionFieldStatus = new ActionField('mStatus', 'Estado de solicitud')
				.addItem('state', 'button')
				.setColor('status', TempColorStates)
				.setPermission('STATUS_BUTTON')
				.setTextFrom('status')
			;

			export const Actives = [
				TempActionFieldStatus
			]
	
			// export const History = [
			// 	TempActionFieldStatus,
			// 	new ActionField('checkComment', 'Comentario')
			// 		.on(ActionCallbackName.ITEM_CONDITION, (item: any): (ActionItem | void) => {
			// 			const { comment } = item
			// 			if (!ValueTools.isNullOrUndefined(comment) && comment !== '')
			// 				return new ActionItem('view', 'icon')
			// 					.setIcon(['far', 'list-alt'])
			// 					.setPermission('COMMENT_ICON')
			// 					.setVariant('blue')
			// 				;
			// 		}
			// 	)
			// ]
	
			export const Services = [
				TempActionFieldStatus,
				new ActionField('actions', 'Acciones')
					.addItem('edit', 'icon')
						.setIcon('pen')
						.setPermission('ACTION_EDIT')
						.setTitle('Editar Servicio')
						.setVariant('green')
					// .addItem('history', 'icon')
					// 	.setIcon('history')
					// 	.setPermission('ACTION_HISTORY')
					// 	.setTitle('Ver Historial')
					// 	.setVariant('blue'),
				// new ActionField('activeStatus', 'Operativo')
				// 	.on(ActionCallbackName.ITEM_CONDITION, (item: any): (ActionItem | void) => {
				// 		const { statusEquipment } = item
				// 		return new ActionItem('status', 'icon')
				// 			.setIcon(statusEquipment ? 'check-circle' : 'times-circle')
				// 			.setPermission('STATUS_ICON')
				// 			.setText(statusEquipment)
				// 			.setVariant(statusEquipment ? 'green' : 'red')
				// 		;
				// 	}
				// )
			]
		}

		export namespace Defaults {
			export const ColorStates: { [key: string]: string } = {
				...TempColorStates
			}
	
			// export const States: { [key: string]: string } = {
			// 	CREATED:    'Creado',
			// 	ACCEPTED:   'Aceptado',
			// 	ROUTE:      'Ruta',
			// 	INIT_JOB:   'Trabajando',
			// 	FINISH_JOB: 'Terminado',
			// 	CONFIRMED:  'Confirmado',
			// 	REJECTED:   'Rechazado',
			// 	ANNULED:    'Anulado',
			// 	RESCHEDULE: 'Reprogramar',
			// 	NONE:       'none'
			// }
	
			// export const StatesOrder: { [key: string]: number } = {
			// 	CREATED:    0,
			// 	ACCEPTED:   1,
			// 	ROUTE:      2,
			// 	INIT_JOB:   3,
			// 	FINISH_JOB: 4,
			// 	CONFIRMED:  5,
			// 	REJECTED:   6,
			// 	ANNULED:    7,
			// 	RESCHEDULE: 8
			// }
	
			// export enum Traceability {
			// 	CREATED    = 1,
			// 	ACCEPTED   = 2,
			// 	ROUTE      = 3,
			// 	INIT_JOB   = 4,
			// 	FINISH_JOB = 5,
			// 	CONFIRMED  = 6,
			// 	REJECTED   = 7,
			// 	ANNULED    = 8,
			// 	RESCHEDULE = 9
			// }
		}

		export namespace JoiSchemas {
			const JoiSchemaLabels = {
				_idClient:         'Cliente',
				_idEquipment:      'Equipo',
				_idService:        'Servicio',
				_idStorage:        'Almacenamiento',
				_idTechnical:      'Técnico',
				clientName:        'Nombre Cliente',
				Description:       'Descripción',
				clientLastName:    'Apellido Cliente',
				clientEmail:       'Correo Cliente',
				dateCreation:      'Fecha de Creación',
				dateProgram:       'Fecha Programada',
				details:           'Detalles',
				equipmentCode:     'Código del Equipo',
				equipmentName:     'Nombre del Equipo',
				status:            'Estado del Servicio',
				statusEquipment:   'Estado del Equipo',
				storageCode:       'Código de Almacenamiento',
				storageName:       'Nombre del Almacenamiento',
				technicalLastName: 'Apellido Técnico',
				technicalName:     'Nombre Técnico',
				workingDay:        'Jornada Trabajo',

			}
	
			// export const AddService = Joi.object({
			// 	_idStorage:      Joi.string().hex().required().label(JoiSchemaLabels._idStorage),
			// 	_idEquipment:    Joi.string().hex().required().label(JoiSchemaLabels._idEquipment),
			// 	_idTechnical:    Joi.string().hex().required().label(JoiSchemaLabels._idTechnical),
			// 	dateProgram:     Joi.string().allow('').required().label(JoiSchemaLabels.dateProgram),
			// 	details:         Joi.string().required().label(JoiSchemaLabels.details),
			// 	statusEquipment: Joi.boolean().required().label(JoiSchemaLabels.statusEquipment),
			// })
			
			export const UpdateService = Joi.object({ 
				_idService:		  Joi.string().required().label(JoiSchemaLabels._idService),
				//_idStorage:     Joi.string().hex().required().label(JoiSchemaLabels._idStorage),
				//_idEquipment:   Joi.string().hex().required().label(JoiSchemaLabels._idEquipment),
				_idTechnical:     Joi.string().hex().required().label(JoiSchemaLabels.technicalName),
				dateProgram:      Joi.string().allow('').required().label(JoiSchemaLabels.dateProgram),
				workingDay:       Joi.string().required().label(JoiSchemaLabels.workingDay),
				statusEquipment:  Joi.string().required().label(JoiSchemaLabels.statusEquipment),
				status: 		  Joi.string().required().label(JoiSchemaLabels.status),
			})
		}

		export namespace Socket {
			export enum Events {
				CREATED = 'ServiceCreated',
				UPDATED = 'ServiceUpdated'
			}
		}
	}
}