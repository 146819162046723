// Classes
import { AxiosManager }     from '@/Classes/Network/AxiosManager'
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { VuexTools }        from '@/Classes/Static/VuexTools'

// Constants
import { Component } from '@/Constants/Global/Component'
import { Server }    from '@/Constants/Global/Server'

// Dependencies
import Axios from 'axios'

// Interfaces
import { Vuex } from '@/Interfaces/Global/Vuex'

// Store
import Store from '@/Store/Global/Default'

// Aliases
const DataTableConstants = Component.VueComponents.Global.DataTable

// Module Export
export default <VuexNotificationsModule<object>> {
	state: {
		tempNotifications: [],
		updateAllInProgress: false
	},

	mutations: {
		storeTempNotifications: function(state, response) {
			state.tempNotifications = response
		},

		storeUpdateAllInProgress: function(state, bool) {
			state.updateAllInProgress = bool
		}
	},

	getters: {
		getStoredTempNotifications: function(state) {
			return state.tempNotifications
		},

		getStoredUpdateAllInProgress: function(state) {
			return state.updateAllInProgress
		}
	},

	actions: {
		fetchNotificationsByIdEquipment: async function({ commit }, params) {
			try {
				const response = await Axios.get(Server.Routes.Notifications.GetNotificationsByIdEquipment, { headers: AxiosManager.AuthenticationHeader, params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchNotificationsByIdNotification: async function({ commit }, params) {
			try {
				const response = await Axios.get(Server.Routes.Notifications.GetNotificationsById, { headers: AxiosManager.AuthenticationHeader, params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchNotificationsByIdRegister: async function({ commit }, params) {
			try {
				const response = await Axios.get(Server.Routes.Notifications.GetNotificationsByIdRegister, { headers: AxiosManager.AuthenticationHeader, params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchNotificationsByIdStorage: async function({ commit }, params) {
			try {
				const response = await Axios.get(Server.Routes.Notifications.GetNotificationsByIdStorage, { headers: AxiosManager.AuthenticationHeader, params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		},

		fetchNotificationsByIdSystem: async function({ commit }, params) {
			try {
				const response = await Axios.get(Server.Routes.Notifications.GetNotificationsByIdSystem, { headers: AxiosManager.AuthenticationHeader, params })
				commit('storeTempNotifications', response.data.body)
				return response.data.body
			}
			catch (err) { DevelopmentTools.printError(err.response) }
		}
	}
}

// Module Interfaces
interface VuexNotificationsModule<Document, State = VuexNotificationsState<Document>, Getters = Vuex.Getters<State>> {
	state: State
	mutations: VuexNotificationsMutations<State, Document>
	getters: Getters
	actions: Vuex.Actions<State, Getters>
}

interface VuexNotificationsState<Document> {
	tempNotifications: Array<Document>
	updateAllInProgress: boolean
}

interface VuexNotificationsMutations<State, Document> extends Vuex.Mutations<State> {
	storeTempNotifications: (state: State, response: Array<Document>) => void
	storeUpdateAllInProgress: (state: State, bool: boolean) => void
}